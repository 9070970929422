<template>
  <div class="orderClock">
    <div v-for="item in serveList" :key="item.serveId" class="projectList">
      <img class="projectImg" :src="item.serveImgUrl" alt="" />
      <div class="information">
        <p class="name">{{ item.serveName }}</p>
        <p class="vip-information">
          <span class="money">
            <font class="moneyIcon">￥</font>{{ item.servePrice }}
          </span>
          <span class="clockBtn" @click="confirmClock(item.project_id)"
            >加钟</span
          >
        </p>
        <p class="vip-information">
          <span
            ><van-icon name="clock-o" class="timeIcon" />{{
              item.serveTime
            }}分钟</span
          >
        </p>
        <p class="synopsis">{{ item.serveIntroduce }}</p>
      </div>
    </div>
    <div class="nullDataDiv" v-if="serveList.length == 0">
      <img src="../../assets/img/error.png" alt="" />
      <span class="text">暂无更多服务</span>
    </div>
  </div>
</template>

<script>
import { getClockProject } from "@/api/order";
export default {
  data() {
    return {
      serveList: []
    };
  },
  created() {
    this.getClockProject();
  },
  methods: {
    // 点击加钟
    confirmClock(item) {
      this.$router.push({
        path: "/order/details",
        query: {
          order_id: this.$route.query.order_id,
          project_id: item.project_id,
          from: "clock"
        }
      });
    },
    // 获取服务项目
    getClockProject() {
      getClockProject({ orderId: this.$route.query.order_id }).then(res => {
        this.serveList = res.data;
      });
    }
  }
};
</script>

<style lang="less" scoped>
.orderClock {
  min-height: 100vh;
  background: #f1f1f1;
  padding: 0.4rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .projectList {
    display: flex;
    margin-bottom: 0.3rem;
    background: white;
    padding: 0.3rem;
    border-radius: 0.2rem;
    .projectImg {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 0.2rem;
      margin-right: 0.4rem;
    }

    .information {
      flex: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .name {
        font-size: 16px;
        font-weight: bold;
      }

      .vip-information {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .clockBtn {
        background: #e82b2f;
        color: #fff;
        font-size: 0.28rem;
        padding: 0.1rem 0.4rem;
        border-radius: 0.4rem;
      }
      .money {
        font-size: 0.36rem;
        font-weight: 600;
        color: orange;
        width: 1.3rem;

        .moneyIcon {
          font-size: 0.24rem;
        }
      }
      .timeIcon {
        font-size: 0.36rem;
        margin-right: 3px;
      }

      .synopsis {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 0.24rem;
        color: rgb(133, 133, 133);
      }
    }
  }
}
</style>
